import axiosInstance from './utils';

export default {
  name: 'whitelists',

  getList() {
    return axiosInstance.get(`/${this.name}`)
      .catch((error) => {
        const apiErrorMsg = error?.response?.data?.error_msg || '';
        console.log(`${this.name}.api.error: ${error}. ${apiErrorMsg}`);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },
};
