<template>
  <div class="pa-6">
    <v-btn @click="getWhiteList">Load whitelist</v-btn>
    <v-textarea class="pa-6"
      v-model="whitelist"
      :loading="loading"
      outlined
      filled
      auto-grow
      dense
      clearable
      clear-icon="mdi-close-circle"
      ></v-textarea>
  </div>
</template>

<script>
import dbAPI from '../api/whitelists.api';

export default {
  name: 'Whitelist',

  components: {
  },

  data: () => ({
    loading: false,
    whitelist: '',
  }),

  methods: {

    getWhiteList() {
      this.whitelist = '';
      this.loading = true;
      this.$nextTick(() => {
        this.$nextTick(() => {
          dbAPI.getList().then((response) => {
            this.whitelist = JSON.stringify(response.data, null, 2);
          }).catch((error) => {
            const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 2,
              { operation: this.$t('crudLabels.read').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          }).then(() => {
            this.loading = false;
          });
        });
      });
    },
  },
};
</script>
